<template>
  <div class="channel overflow">
    <div class="sub-menu">
      <div class="menu-it selectDate"  :class="{cur: current =='nowupload' }">
        <el-select v-model="timeValue" placeholder="请选择" @focus="changeMenu('nowupload')" @change="changeMethod">
          <el-option
            v-for="item in timeArray"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div
        class="menu-it"
        v-for="(item, index) in subMenuList"
        :key="index"
        @click="changeMenu(item.key)"
        :class="{ cur: current == item.key }"
      >
       {{ item.name }}
      </div>

    </div>
    <div class="view-content">
      <keep-alive>
      <component
        ref="components"
        v-bind:is="currentTabComponent"
        @changeMenu="changeMenu"
      ></component>
    </keep-alive>
    </div>
  </div>
</template>
<script>
  import {getDays,nowDataMethod} from '../../utils/index'
export default {
  components: {
    nowupload:()=>import("../components/nowupload.vue"),
    incomedetails: () => import("../components/incomedetails.vue"),
    withdrawal: () => import("../components/withdrawal.vue"),
    withdrawalrecord: () => import("../components/withdrawalrecord.vue"),
  },
  data() {
    return {
      current: "nowupload",
      currentTabComponent: "nowupload",
      timeValue:'',//时间value
      timeArray:[],//时间数组
      subMenuList: [
        {
          key: "incomedetails",
          name: "收益明细",
        },
        {
          key: "withdrawal",
          name: "我要提现",
        },
        {
          key: "withdrawalrecord",
          name: "提现记录",
        },
      ],
    };
  },
  activated() {
    this.timeValue=nowDataMethod()
    this.timeArray=getDays(1).map(item=>{
      return{
      value: item,
      label:item==nowDataMethod()?'今日上传':item,
      }})
},
  created() {
    
  },
  methods: {
    //今日上传切换
    changeMethod(){
      console.log('timeValue',this.timeValue)
      this.$refs.components.params.page_index=1;
      this.$refs.components.params.start_time=this.timeValue;
       this.$refs.components.getList(1);
    },
    //切换菜单
    async changeMenu(key) {
      console.log('key',key)
      //if(key=='nowupload'){return;}
      this.current = key;
      this.currentTabComponent = key;
    },
  },
};
</script>
<style lang="less" scoped>
.channel {
  height:100%;
  .sub-menu {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    .menu-it {
      min-width: 105px;
      height: 30px;
      line-height: 30px;
      background: linear-gradient(0deg, #d8d8d8, #f7f7f7);
      padding: 0 10px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      margin-right: 14px;
      text-align: center;
      cursor: pointer;
    }
  
    .cur {
      background: linear-gradient(180deg, #3f90f9, #314bfb);
      color: #ffffff;
      /deep/ .el-select .el-input__inner{
        background: linear-gradient(180deg, #3f90f9, #314bfb);
      color: #ffffff;
      }
      /deep/ .el-input__icon {
   color:#fff;
}
    }
  }
  .view-content {
    /* border: 1px solid #dcdcdc; */
    height: calc(100% - 41px);
    /* max-height: 100%; */
    /* padding: 18px 24px; */
  }
}
.selectDate{
  padding:0 !important;
  background:transparent !important;

/deep/ .el-select 
{.el-input__inner{
  border: none ;
  width: 120px;
  background: linear-gradient(0deg, #d8d8d8, #f7f7f7);
  border-radius:0;
  height: 30px;
    line-height: 30px;
    color:#000;
}
.el-input__icon {
  
    line-height: 30px;
}
  .el-input.is-focus{.el-input__inner {
    border: none !important;
}
}}
}
</style>
